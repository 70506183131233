import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import TruncateText from "../utilities/truncate-text"
import { Link } from "gatsby"

const HeroSection = ({ coverArticle, coverArticleCallout }) => {
  const { title, slug, description, cover } = coverArticle
  const firstBlock = coverArticle.blocks[0]
  const body = firstBlock.body.data.body

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center md:justify-start h-hero my-24">
      <div className="w-full lg:w-2/3">
        <GatsbyImage 
          image={cover.localFile.childImageSharp.gatsbyImageData} 
          alt={title}
          className="h-hero shadow-lg hover:shadow-xl"
        />
      </div>
      <div className="ml-8 w-full md:w-1/3 h-full">
        <div className="flex flex-col justify-between h-full">
          <div className="text-base font-bold text-red-500 uppercase mb-3">{coverArticleCallout}</div>
          <h1 className="text-4xl font-bold text-red-600 ">{description}</h1>
          <p className="my-4">
            <TruncateText text={body} />
          </p>
          <div className="mt-auto">
            <Link className="bg-gray-200 text-blue-900 font-medium px-4 py-2 flex items-center justify-center w-full uppercase font-gin hover:bg-blue-900 hover:text-gray-200" to={`/articles/${coverArticle.slug}`}>
              Continue Reading
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6"><path d="M9 18l6-6-6-6"/></svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection