import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import ArticlesGrid from "../components/articles-grid"
import Seo from "../components/seo"
import HeroSection from "../components/hero-section"
import LetterSection from "../components/letter-section"

const IndexPage = () => {
  const { allStrapiArticle, strapiGlobal, strapiHome } = useStaticQuery(graphql`
    query {
      allStrapiArticle {
        nodes {
          ...ArticleCard
        }
      }
      strapiGlobal {
        siteName
        siteDescription
      }
      strapiHome {
        id
        coverArticleCallout
        heroLetterContent {
          data {
            childMarkdownRemark {
              id
              html
            }
          }
        }
        heroLetterList {
          data {
            childMarkdownRemark {
              id
              html
            }
          }
        }
        heroLetterHeaderImage {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        heroLetterSignatureImage {
          alternativeText
          localFile {
            url
          }
        }
        coverArticle {
          id
          slug
          title
          description
          blocks {
            ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
              id
              body {
                data {
                  body
                }
              }
            }
          }
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.77)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo seo={{ metaTitle: "Home" }} />
      <main>
        <HeroSection coverArticle={strapiHome.coverArticle} coverArticleCallout={strapiHome.coverArticleCallout} />
        <LetterSection strapiHome={strapiHome} />
      </main>
    </Layout>
  )
}

export default IndexPage
