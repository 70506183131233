import React from "react";

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  const truncated = text.substr(0, maxLength);
  const lastSpace = truncated.lastIndexOf(" ");

  return truncated.substr(0, lastSpace) + "...";
};

const TruncateText = ({ text }) => {
  const truncatedText = truncateText(text, 150);

  return <p>{truncatedText}</p>;
};

export default TruncateText;