import React from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import TruncateText from "../utilities/truncate-text"
import BlockRichText from "./block-rich-text"

const CustomBullet = () => {
  return (
    <span className="w-4 h-4 inline-flex items-center justify-center bg-blue-900 text-white rounded-full">
      X
    </span>
  );
};

const LetterSection = ({ strapiHome }) => {
  const { heroLetterHeaderImage, heroLetterSignatureImage, heroLetterContent, heroLetterList } = strapiHome
  const content = heroLetterContent.data.childMarkdownRemark.html
  const list = heroLetterList.data.childMarkdownRemark.html
  

  return (
    <div className="bg-blue-950 flex flex-row">
      <div className="w-1/2 flex items-center justify-center transform -rotate-3">
        <div className="bg-white p-8 max-w-md shadow-letter">
          <div className="flex items-center ml-auto lg:ml-0">
            <div className="h-full max-h-24 ml-auto mr-auto">
              <GatsbyImage
                image={getImage(heroLetterHeaderImage.localFile)}
                className="w-36"
                alt="Logo"
              />
            </div>
          </div>
          <p className="text-lg font-serif leading-relaxed">
            <span
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
          </p>
          <p className="font-serif text-3xl font-bold">
            <img src={heroLetterSignatureImage.localFile.url} alt="My Image" />
          </p>
        </div>
      </div>
      <div className="w-1/2">
        <div className="max-w-md mx-auto p-8 agenda h-full flex flex-items-center">
          <div className="mt-auto mb-auto"
            dangerouslySetInnerHTML={{
              __html: list
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LetterSection;